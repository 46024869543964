import React from "react"

const Footer = () => (
  <footer
    className="
      bg-blue-900
      md:flex
      justify-between
      p-5
      md:p-20
      text-white
    "
  >
    <div
      className="
        md:space-y-2
        md:w-1/2
      "
    >
      <p>
        <a
          href="https://fosstodon.org/@podlove"
          target="_blank"
          rel="noreferrer"
        >
          @podlove on Mastodon
        </a>
        <span className="text-gray-500"> – We toot sometimes</span>
      </p>
      <p>
        <a href="https://github.com/podlove" target="_blank" rel="noreferrer">
          Podlove on GitHub
        </a>
        <span className="text-gray-500"> – Checkout our Open Source Code</span>
      </p>
      <p>
        <a
          href="https://opencollective.com/podlove"
          target="_blank"
          rel="noreferrer"
        >
          Podlove on Open Collective
        </a>
        <span className="text-gray-500"> – Support Podlove</span>
      </p>
      <p>
        <a
          href="https://community.podlove.org/"
          target="_blank"
          rel="noreferrer"
        >
          Podlove Community
        </a>
        <span className="text-gray-500">
          {" "}
          – Our Suport Forum for your Questions
        </span>
      </p>
    </div>
    <div
      className="
        md:space-y-2
        text-right
        md:w-1/2
      "
    >
      <p>© {new Date().getFullYear()} Podlove</p>
      <p>
        <a href="/contact">Contact</a>
      </p>
      <p>
        <a href="/privacy">Privacy & Data Protection</a>
      </p>
      <p>
        <a href="/legal">Legal / Impressum</a>
      </p>
    </div>
  </footer>
)

export default Footer
